const APP_NAME = "Allsetra";
const APP_ICON = "/app-favicon-logo.png";
const NOTIFICATION_AUDIO = "/notification.mp3";

const createNotification = (body: string): void => {
    if (!("Notification" in window)) {
        console.warn("Browser notification is not supported");
        return;
    }

    if (!body) {
        console.warn("Notification body is empty");
        return;
    }

    try {
        new Notification(APP_NAME, {
            body,
            icon: APP_ICON,
        });
    } catch (error) {
        console.error("Error creating notification:", error);
    }
};

const playAudio = async (): Promise<void> => {
    try {
        const audio = new Audio(NOTIFICATION_AUDIO);
        await audio.play();
    } catch (error) {
        console.error("Failed to play notification sound:", error);
    }
};

export const handleAlarmNotification = async (event: {
    objectName: string;
    alarmTypeName: string;
    alarmOwnerName: string;
    alarmOwnerId: string;
}): Promise<void> => {
    if (!event?.alarmTypeName || !event?.objectName) {
        console.warn("Invalid event data");
        return;
    }

    const notificationBody = `${event.alarmTypeName} | ${event.objectName} ${event.alarmOwnerName ? `| ${event.alarmOwnerName}` : ""}`;

    try {
        if (Notification.permission === "granted") {
            await playAudio();
            createNotification(notificationBody);
        } else if (Notification.permission !== "denied") {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                await playAudio();
                createNotification(notificationBody);
            } else {
                console.warn("Notification permission denied by user");
            }
        } else {
            console.warn("Notifications are blocked");
        }
    } catch (error) {
        console.error("Error handling alarm notification:", error);
    }
};
