import { FC, useState, useEffect, useMemo, useRef } from "react";
import { GoogleMap, PolygonF } from "@react-google-maps/api";
import { PageLoader } from "@vilocnv/allsetra-core";
import {
  IconBox,
  IconsWrapper,
  IconTooltip,
  AlarmMapContainer,
} from "../Map.styled";
import MapViewport from "../children/MapViewport";
import AlarmMarkers from "./children/AlarmMarkers";

// Data
import { useAppDispatch, useAppSelector, useMap } from "hooks";
import {
  extractObjectsDataForMap,
  transformGeoJSONCoordsToPaths,
  getAlarmCoordsWithObjectLocation,
  averageGeolocation,
  getBoundsZoomLevel,
  getElementSize,
} from "app/data/helpers";
import {
  AccountPersonBlack,
  AccountPersonGray,
  GeoZoneBlack,
  GeoZoneGray,
} from "assets/icons";
import {
  selectAssociatedGeozones,
  selectObjectsState,
} from "app/data/selectors";
import {
  getAccountGeozones,
  resetAccountGeozones,
  getObjectsLocationsThunk,
  resetAllObjects,
} from "app/features";
import { isObject, isEmpty } from "lodash";
import {
  POLYGON_OPTIONS,
  ALARM_POLYGON_OPTIONS,
  LegendsList,
} from "app/data/constants";
import { LegendBox } from "components/ui/legendBox/LegendBox";
import { useTranslation } from "react-i18next";

interface AlarmDeskMapProps {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  objects: Array<any>;
  selectedAlarmId: string | null;
  shouldRenderGeozone: boolean;
  setShouldRenderGeozone: React.Dispatch<React.SetStateAction<boolean>>;
  shouldRenderAccounts: boolean;
  setShouldRenderAccounts: React.Dispatch<React.SetStateAction<boolean>>;
}

const AlarmDeskMap: FC<AlarmDeskMapProps> = ({
  center,
  zoom,
  objects,
  selectedAlarmId,
  shouldRenderGeozone,
  setShouldRenderGeozone,
  shouldRenderAccounts,
  setShouldRenderAccounts,
}) => {
  const [shouldRenderMarkers, setShouldRenderMarkers] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
  const [customMarkers, setCustomMarkers] = useState([]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const alarmContainerRef = useRef<HTMLDivElement>();

  const { alarmAssociatedGeozones } = useAppSelector(selectAssociatedGeozones);

  const { allObjects } = useAppSelector(selectObjectsState);

  const { mapRef, isLoaded, loadError, onMapLoad } = useMap({
    disableNavigator: true,
  });

  const selectedAlarm = useMemo(() => {
    if (!isEmpty(objects)) {
      return objects?.find(
        (item: { uniqueId: string }) => item.uniqueId === selectedAlarmId
      );
    }

    return undefined;
  }, [selectedAlarmId, objects]);

  const selectedAlarmGeozone = useMemo(() => {
    return alarmAssociatedGeozones?.find(
      (geo: any) => geo?.uniqueId === selectedAlarm?.geozoneId
    );
  }, [selectedAlarm, alarmAssociatedGeozones]);

  const centerOfAlarm = useMemo(() => {
    if (isEmpty(selectedAlarm)) {
      return center;
    } else {
      const coords = getAlarmCoordsWithObjectLocation(selectedAlarm);
      const averageGeolocationCenter = averageGeolocation(coords);
      const newCenter = {
        lat: averageGeolocationCenter.latitude || 0,
        lng: averageGeolocationCenter.longitude || 0,
      };

      mapRef?.panTo({
        lat: averageGeolocationCenter.latitude || 0,
        lng: averageGeolocationCenter.longitude || 0,
      });

      return newCenter;
    }
  }, [selectedAlarm]);

  const renderPolygons = () => {
    return (
      alarmAssociatedGeozones &&
      alarmAssociatedGeozones.map((geozone) => {
        const geoJSON = JSON.parse(geozone.geoJson);

        if (!isObject(geoJSON)) return null;

        const options =
          geozone?.uniqueId === selectedAlarmGeozone?.uniqueId
            ? ALARM_POLYGON_OPTIONS
            : POLYGON_OPTIONS;

        // @ts-ignore
        return geoJSON?.geometry && geoJSON?.geometry?.coordinates ? (
          <PolygonF
            key={geozone.uniqueId}
            paths={transformGeoJSONCoordsToPaths(
              //@ts-ignore
              geoJSON?.geometry?.coordinates
            )}
            options={options}
          />
        ) : null;
      })
    );
  };

  const handleMarkerClick = (markerIndex: number) => {
    setSelectedMarker(markerIndex);
  };

  const handleGeozone = () => {
    setShouldRenderGeozone((prevState: boolean) => {
      const newState = !prevState;
      if (newState) {
        // @ts-ignore
        dispatch(getAccountGeozones(selectedAlarm?.alarmOwnerId));
      } else {
        dispatch(resetAccountGeozones());
      }
      return newState;
    });
  };

  const handleObjects = () => {
    setShouldRenderAccounts((prevState: boolean) => {
      const newState = !prevState;
      if (newState) {
        dispatch(
          getObjectsLocationsThunk({
            accountId: selectedAlarm?.alarmOwnerId || "",
            values: {
              groups: [],
              objectTypes: [],
              driverId: "",
            },
          })
        );
      } else {
        dispatch(resetAllObjects());
      }
      return newState;
    });
  };

  useEffect(() => {
    if (objects.length > 0) {
      setShouldRenderMarkers(true);
    }

    // When an alarm is expended
    // We move the map center to that alarm
    if (!isEmpty(selectedAlarmId) && !isEmpty(objects)) {
      const alarm = objects?.find(
        (obj: { uniqueId: string }) => obj.uniqueId === selectedAlarmId
      );

      if (alarm) {
        const coords = getAlarmCoordsWithObjectLocation(alarm);
        const averageGeolocationCenter = averageGeolocation(coords);
        const zoomLevel = getBoundsZoomLevel(
          coords,
          getElementSize(alarmContainerRef.current)
        );

        mapRef?.panTo({
          lat: averageGeolocationCenter.latitude || 0,
          lng: averageGeolocationCenter.longitude || 0,
        });
        mapRef?.setZoom(zoomLevel || 8);
      }
    } else {
      mapRef?.panTo({
        lat: center.lat || 0,
        lng: center.lng || 0,
      });
      mapRef?.setZoom(zoom);
    }
  }, [selectedAlarmId, alarmContainerRef]);

  useEffect(() => {
    if (!isEmpty(selectedAlarm) && shouldRenderGeozone) {
      dispatch(getAccountGeozones(selectedAlarm?.alarmOwnerId));
    }
  }, [selectedAlarmId]);

  useEffect(() => {
    setCustomMarkers(extractObjectsDataForMap(allObjects));
  }, [allObjects]);

  const renderMap = () => (
    <AlarmMapContainer ref={alarmContainerRef}>
      <LegendBox />
      {selectedAlarm?.alarmOwnerId &&
      <IconsWrapper>
        <IconTooltip title={t("alarmDesk.labels.showAssociatedGeozones")} arrow>
          <IconBox onClick={() => handleGeozone()}>
            {shouldRenderGeozone ? <GeoZoneBlack /> : <GeoZoneGray />}
          </IconBox>
        </IconTooltip>
        <IconTooltip title={t("alarmDesk.labels.showAssociatedAccounts")} arrow>
          <IconBox onClick={() => handleObjects()}>
            {shouldRenderAccounts ? (
              <AccountPersonBlack />
            ) : (
              <AccountPersonGray />
            )}
          </IconBox>
        </IconTooltip>
      </IconsWrapper>
      }
      <GoogleMap
        center={centerOfAlarm}
        zoom={zoom}
        onLoad={onMapLoad}
        options={{
          styles: [
            {
              featureType: "poi",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
        mapContainerStyle={{ height: "calc(100vh - 104px)", width: "100%" }}
      >
        {shouldRenderMarkers && (
          <AlarmMarkers
            objects={objects}
            selectedMarker={selectedMarker}
            handleMarkerClick={handleMarkerClick}
            selectedAlarmId={selectedAlarmId}
            alarmGeozone={selectedAlarmGeozone}
          />
        )}
        {shouldRenderGeozone && renderPolygons()}
        {shouldRenderAccounts && (
          <MapViewport
            map={mapRef}
            markers={customMarkers}
            handleMarkerClick={handleMarkerClick}
            selectedMarker={selectedMarker}
          />
        )}
      </GoogleMap>
    </AlarmMapContainer>
  );

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <PageLoader />;
};

export default AlarmDeskMap;
