import { toast, types } from "@vilocnv/allsetra-core";
import {
  setIsLockedOfAlarm,
  getAlarmsByQueryThunk,
  getSpecificAlarmThunk,
  getAlarmsByQuerySilentlyThunk,
} from "app/features";
import { AppDispatch, store } from "app/store";
import { alarmParams } from "../constants";
import { handleAlarmNotification } from "../services/NotificationService";

const recentlyProcessedAlarms = new Set<string>();
const DEDUPLICATION_WINDOW_MS = 5000;

const isAlarmRecentlyProcessed = (alarmId: string): boolean => {
  return recentlyProcessedAlarms.has(alarmId);
};

const markAlarmAsProcessed = (alarmId: string): void => {
  recentlyProcessedAlarms.add(alarmId);
  setTimeout(() => {
    recentlyProcessedAlarms.delete(alarmId);
  }, DEDUPLICATION_WINDOW_MS);
};

export const signalRGenerateSuccessToastMessage = (
  eventName: string,
  eventFor: string,
  actionType: string
): string => {
  return `${eventFor} with the name "${eventName}" has been ${actionType}.`;
};

export const signalRAlarmsEventsListener = async (
  event: any,
  dispatch: AppDispatch
) => {
  if (event.uniqueId && isAlarmRecentlyProcessed(event.uniqueId)) {
    return;
  }

  switch (event.eventName) {
    case types.BackendEventsEnum.AlarmUpdatedEvent:
      const account = store.getState().rootReducer.dashboardReducer.account;

      dispatch(
        setIsLockedOfAlarm({
          alarmId: event.uniqueId,
          lockedBy: event.isLocked ? { uniqueId: event.author || "" } : null,
          isLocked: event.isLocked,
          loggedUserId: account?.uniqueId || "",
        })
      );
      break;

    case types.BackendEventsEnum.AlarmCreatedEvent:
      markAlarmAsProcessed(event.uniqueId);
      await handleAlarmNotification(event);
      dispatch(
        getSpecificAlarmThunk({
          alarmId: event.uniqueId,
          isFromAlarmCreatedEvent: true,
        })
      );
      break;

    case types.BackendEventsEnum.AlarmCommentCreatedEvent:
      dispatch(getAlarmsByQueryThunk(alarmParams));
      break;

    case types.BackendEventsEnum.AlarmClearedEvent:
      dispatch(getAlarmsByQuerySilentlyThunk(alarmParams));
      break;
  }
};

export const signalREventsRaisedListener = (
  event: any,
  dispatch: AppDispatch
) => {
  switch (event.eventName) {
    //
    // Devices Events
    //
    case types.BackendEventsEnum.DeviceImmobilizerStatusChangeReportedEvent:
      toast.success("Device immobilizer status has been changed");
      break;

    case types.BackendEventsEnum.RawCommandSentToDeviceEvent:
      toast.success("The raw command was delivered to the device");
      break;
  }
};
