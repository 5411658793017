import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  ChangeGroupForm,
  DeleteConfirmationModal,
  GroupCard,
  types,
  useDispatchOnParams,
} from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector, useSelectSearch } from "hooks";
import { getAccountObjectsThunk, getObjectsByQueryThunk } from "app/features";
import {
  selectAccountGroups,
  selectQueriedObjectsState,
} from "app/data/selectors";
import { FormikHelpers } from "formik";
import {
  createOrUpdateAccountGroupsThunk,
  getAllAccountGroupsThunk,
  removeGroupFromAccountThunk,
} from "app/features/accounts/actions/accountGroupsActions";
import { useNavigate } from "react-router-dom";

interface Props {
  accountId: string;
}

const AccountGroupsSection: FC<Props> = ({ accountId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Global State
  const { accountGroups, accountGroupsLoading } =
    useAppSelector(selectAccountGroups);
  const { objects } = useAppSelector(selectQueriedObjectsState);

  useDispatchOnParams(getAccountObjectsThunk, { args: { accountId } });

  // Local State
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [editValues, setEditValues] = useState<any>({});
  const [selectedLocalIds, setSelectedLocalIds]: Array<any> = useState([]);

  const toggleAddGroupModal = () => {
    setAddGroupModal(!addGroupModal);
    setEditValues({});
  };

  useEffect(() => {
    dispatch(getAllAccountGroupsThunk(accountId));
  }, []);

  const openEditModal = (item: any) => {
    setEditValues(item);
    setAddGroupModal(true);
    setSelectedGroupId(item.uniqueId);
  };

  const openDeleteConfirmationModal = (groupId: string) => {
    setSelectedGroupId(groupId);
    setOpenDeleteModal(true);
  };

  const removeGroupHandler = () => {
    if (selectedGroupId && accountId) {
      dispatch(
        removeGroupFromAccountThunk({
          accountId,
          groupId: selectedGroupId,
        })
      );
    }

    setOpenDeleteModal(false);
  };

  const addGroupHandler = async (
    values: types.IChangeGroup,
    formikHelpers: FormikHelpers<types.IChangeGroup>
  ) => {
    formikHelpers.setSubmitting(true);
    let data = { ...values };
    if (!values.uniqueId) {
      data["alarmsConfiguration"] = { alarms: [] };
    }
    const { type } = await dispatch(
      createOrUpdateAccountGroupsThunk({
        accountId,
        values: data,
        groupId: values.uniqueId,
      })
    );

    if (type === "accounts/createOrUpdateAccountGroupsThunk/fulfilled") {
      toggleAddGroupModal();
      formikHelpers.resetForm();
    }

    formikHelpers.setSubmitting(false);
  };

  const handleAlarmConfig = (item?: any) => {
    navigate(
      `/dashboard/account-manager/groups/${
        editValues?.uniqueId || item?.uniqueId
      }/alarms-config`,
      {
        state: editValues?.uniqueId ? editValues : item,
      }
    );
  };

  const handleObjectsSearch = (value: string) => {
    dispatch(
      getObjectsByQueryThunk({
        itemsPerPage: 10,
        page: 1,
        where: [{ field: "name", value, type: 0 }],
      })
    );
  };

  const extractObjects = useMemo(() => {
    return selectedGroupId
      ? accountGroups.find(
          (item: { uniqueId: string }) => item.uniqueId === selectedGroupId
        )?.objects || []
      : [];
  }, [selectedGroupId, accountGroups]);

  const selectedLocalObjects = useSelectSearch(selectedLocalIds, objects);

  const handleOnChangeGroupForm = (val: any) => {
    setSelectedLocalIds(val);
  };

  let formObjects = [...extractObjects, ...selectedLocalObjects];

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        marginBottom={2}
      >
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          text="Add group"
          onClick={toggleAddGroupModal}
        />
      </Box>
      {accountGroups.length === 0 && !accountGroupsLoading && (
        <Box display="flex" justifyContent="center">
          <Typography variant={"body1"}>
            There are no records to display
          </Typography>
        </Box>
      )}
      {accountGroupsLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={35} />
        </div>
      ) : (
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          spacing={{ xs: 2, md: 2 }}
        >
          {accountGroups.map((item: any, index: number) => (
            <Grid item key={`${index}`}>
              <GroupCard
                groupTitle={item?.name}
                onDelete={() => openDeleteConfirmationModal(item?.uniqueId)}
                groupObjects={item?.objects}
                onEdit={() => openEditModal(item)}
                onAlarmClick={() => handleAlarmConfig(item)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <ChangeGroupForm
        open={addGroupModal}
        onClose={toggleAddGroupModal}
        accountId={accountId}
        objects={formObjects}
        initialValues={editValues}
        theme={theme}
        onSubmit={addGroupHandler}
        onAlarmConfigClick={handleAlarmConfig}
        handleDebounce={handleObjectsSearch}
        //@ts-ignore
        onChange={handleOnChangeGroupForm}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to remove a group from account"
        subTitle="Do you really want to remove this group from account? This process cannot be undone."
        primaryBtnProps={{ onClick: removeGroupHandler }}
        theme={theme}
      />
    </Box>
  );
};

export default AccountGroupsSection;
