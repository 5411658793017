import React, { Fragment, useEffect, useState } from "react";
import {
  MarkerF,
  OverlayViewF,
  OverlayView,
  InfoWindowF,
} from "@react-google-maps/api";
import { MarkerBlip, MarkerLabel } from "../Map.styled";
import { MapObjectCard } from "@vilocnv/allsetra-core";

interface CustomMarkerClustererProps {
  markers: Array<any>;
  handleMarkerClick: (marker: any) => void;
  map: any;
  selectedMarker: number | null;
}

function createKey(location: any) {
  return location.lat + location.lng;
}

const getPixelPositionOffset = () => ({
  x: 20,
  y: -29,
});

const MapViewport: React.FC<CustomMarkerClustererProps> = ({
  markers,
  handleMarkerClick,
  map,
  selectedMarker,
}) => {
  // Local State
  const [visibleMarkers, setVisibleMarkers] = useState(markers);

  useEffect(() => {
    if (map) {
      const updateVisibleMarkers = () => {
        let newVisibleMarkers = markers;
        let mapBounds = map.getBounds();

        if (map.zoom > 13) {
          newVisibleMarkers = markers.filter((marker) => {
            return mapBounds.contains(
              new window.google.maps.LatLng(marker.lat, marker.lng)
            );
          });
        }
        setVisibleMarkers(newVisibleMarkers);
      };

      window.google.maps.event.addListener(map, "idle", updateVisibleMarkers);

      updateVisibleMarkers();
    }
  }, [map, markers]);

  return (
    <Fragment>
      {visibleMarkers.map((marker, i) => (
        <div key={`${i}`}>
          <MarkerF
            key={createKey(marker)}
            position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
            onClick={() => handleMarkerClick(marker.uniqueId)}
            icon={
              marker.iconUrl
                ? {
                    url: marker.iconUrl,
                    scaledSize: new window.google.maps.Size(28, 28),
                  }
                : undefined
            }
          >
            {selectedMarker === marker?.uniqueId && (
              <InfoWindowF
                position={{ lat: marker?.lat || 0, lng: marker?.lng || 0 }}
                onCloseClick={() => handleMarkerClick(null)}
              >
                <MapObjectCard
                  label={marker?.name || "N/A"}
                  id={marker?.aNumber || "N/A"}
                  //@ts-ignore
                  address={marker?.location?.resolvedAddress || "N/A"}
                  accuracy={marker?.accuracy || "N/A"}
                  time={marker?.formattedDate || "N/A"}
                ></MapObjectCard>
              </InfoWindowF>
            )}
          </MarkerF>
          {map.zoom > 14 && (
            <OverlayViewF
              position={{ lat: marker?.lat, lng: marker?.lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <MarkerLabel>{marker?.name || "N/A"}</MarkerLabel>
              <MarkerBlip></MarkerBlip>
            </OverlayViewF>
          )}
        </div>
      ))}
    </Fragment>
  );
};

export default MapViewport;
