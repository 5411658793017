export function clearIndexedDB(): Promise<void> {
  const dbName = "adminDB";

  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(dbName);

    request.onsuccess = () => {
      console.log(`Database ${dbName} successfully deleted`);
      resolve();
    };

    request.onerror = (event) => {
      console.error(`Error deleting database ${dbName}:`, event);
      reject(event);
    };

    request.onblocked = () => {
      console.log(
        `Deletion of database ${dbName} is blocked. Close other tabs that are using it.`
      );
    };
  });
}

export function clearObjectStores(): Promise<void> {
  const dbName = "adminDB";
  const storeNames = ["adminStore"];

  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = (event) => {
      const db = request.result;
      const transaction = db.transaction(storeNames, "readwrite");

      storeNames.forEach((storeName) => {
        const objectStore = transaction.objectStore(storeName);
        const clearRequest = objectStore.clear();

        clearRequest.onsuccess = () => {
          console.log(`Object store ${storeName} cleared`);
        };

        clearRequest.onerror = (event) => {
          console.error(`Failed to clear object store ${storeName}:`, event);
          reject(event);
        };
      });

      transaction.oncomplete = () => {
        console.log("All object stores cleared");
        resolve();
      };
    };

    request.onerror = (event) => {
      console.error("Error opening the database:", event);
      reject(event);
    };
  });
}
